import React, {useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import qs from "qs";
import BlockContent from "../components/BlockContent";
import MainLayout from "../templates/MainLayout";
import Input from "../ui/Input";
import PlainButton from "../ui/PlainButton";
import Strip from "../ui/Strip";
import XCol from "../ui/XCol";
import XText from "../ui/XText";

const query = graphql`
  {
    sanityGeneral(_id: {eq: "general"}) {
      generatedLinkText
      _rawGenerateIdText
    }
  }
`;

const GenerateId = (props) => {
  const {sanityGeneral: labels} = useStaticQuery(query);
  const [email, setEmail] = useState("");
  const [link, setLink] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmail("");
    setLink(`${window.location.origin}/?${qs.stringify({id: btoa(email)})}`);
  };

  return (
    <MainLayout {...props}>
      <Strip pt={4} pb={5} sp={5} fillParent size="xs">
        {link ? (
          <XCol sp={3}>
            <XText>{labels.generatedLinkText}</XText>
            <Input readOnly value={link} />
          </XCol>
        ) : (
          <>
            <BlockContent content={labels._rawGenerateIdText} />
            <XCol sp={3} as="form" onSubmit={handleSubmit} align="start">
              <Input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
              <PlainButton type="submit">Link erstellen</PlainButton>
            </XCol>
          </>
        )}
      </Strip>
    </MainLayout>
  );
};

export default GenerateId;
